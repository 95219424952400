




import {
 defineComponent, useContext, useRoute,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ClearCache',
  // eslint-disable-next-line consistent-return
  middleware({ redirect, route }) {
    if (!route.query.token) {
      return redirect('/');
    }
  },
  setup() {
    const { $config, $axiosClearCache, error } = useContext();
    const route = useRoute();

    const {
        clearAxiosCacheToken,
    } = $config;

    if (route.value.query.token === clearAxiosCacheToken) {
        $axiosClearCache();
    } else {
        console.log('Clear Cache Token does not match');
        error({ statusCode: 401 });
    }
    return {};
  },
});
